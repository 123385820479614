
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {Prop} from 'vue-property-decorator';

    import {
        AnimalModule,
    } from '@/store/modules';

    import {
        IAnimal,
        IClient,
    } from '@/types';

    @Component<AnimalInsurance>({
        components: {},
    })
    export default class AnimalInsurance extends Vue {
        @Prop({
            type: Object,
            required: true,
            default: false,
        })
        public selectedAnimal!: IAnimal;

        @Prop({
            type: Object,
            required: true,
            default: false,
        })
        public client!: IClient;

        public step: number = 0;

        public saveInsuranceStatus(status: string) {
            if (status === 'enabled') {
                getModule(AnimalModule, this.$store)
                    .updateAnimalInsurance({
                        client_id: this.client.id,
                        animal_id: this.selectedAnimal.id,
                        status,
                    })
                ;
            }

            this.step = status === 'none' ? 1 : 2;
        }

        public saveSendInformation(response: boolean) {
            getModule(AnimalModule, this.$store)
                .updateAnimalInsurance({
                  client_id: this.client.id,
                  animal_id: this.selectedAnimal.id,
                  status: 'none',
                  send_information: response,
                })
            ;

            this.step = 2;
        }

    }
