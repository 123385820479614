
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {namespace} from 'vuex-class';
    import LoginFormCard from '@/components/LoginFormCard.vue';
    import LeadForm from '@/components/santevet/LeadForm.vue';
    import TheFooter from '@/components/layout/TheFooter.vue';
    import {
        IUser,
    } from '@/types';

    const accountNamespace = namespace('account');

    @Component<ViewSantevet>({
        components: {
            LoginFormCard,
            LeadForm,
            TheFooter,
        },
    })
    export default class ViewSantevet extends Vue {
        @accountNamespace.Getter('loggedUser')
        public loggedUser!: IUser|null;

        public queryFrom: Record<string, {}> = this.$route.query;
        public utmKey: string[] = ['utm_source', 'utm_medium', 'utm_content', 'utm_campaign']
        
        /* Verification des clés utm et reformatage du tableau des utm valides*/
        get hasKeyFrom() {
            const keyQueryFrom = Object.keys(this.queryFrom);
            const fitKey = keyQueryFrom.filter(cle => this.utmKey.includes(cle));
            return fitKey.map(cle => ({ [cle]: this.queryFrom[cle] }));
        }
        
    }
