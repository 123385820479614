
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {getModule} from 'vuex-module-decorators';
    import {namespace} from 'vuex-class';
    import {Prop} from 'vue-property-decorator';

    import {AccountModule, SnackbarModule} from '@/store/modules';

    const accountNamespace = namespace('account');

    @Component<LoginFormCard>({})
    export default class LoginFormCard extends Vue {
        public loginFormValid: boolean = false;
        public username: string|null = null;
        public password: string|null = null;
        public showPassword: boolean = false;

        public usernameRules = [
            (v: string) => !!v || 'L\'adresse e-mail est requise',
            (v: string) => /.+@.+\..+/.test(v) || 'Le format de l\'adresse e-mail est incorrect',
        ];

        public passwordRules = [
            (v: string) => !!v || 'Le mot de passe est requis',
            (v: string) => (v && v.length >= 6) || 'Le mot de passe doit contenir au moins 6 caractères',
        ];

        @Prop({
            type: Boolean,
            required: false,
            default: true,
        })
        public showSuccessMessage!: boolean;

        @Prop({
            type: String,
            required: false,
        })
        public initialUsername!: string;

        @accountNamespace.Getter('loginStatus')
        public authStatus!: string|null;

        public submitLogin() {
            if (this.username && this.password) {
                const snackbarModule = getModule(SnackbarModule, this.$store);

                getModule(AccountModule, this.$store)
                    .login({username: this.username, password: this.password})
                    .then(() => {
                        snackbarModule
                            .display({
                                message: 'Bienvenue sur Vetolib !',
                                position: 'top',
                                color: 'success',
                            })
                        ;

                        this.$emit('logged');
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            snackbarModule
                                .display({
                                    message: 'Ces identifiants correspondent à un compte professionnel.<br>Pour vous connecter, <a href="https://pro.vetolib.fr/connexion">cliquez ici</a>',
                                    position: 'top',
                                    color: 'error',
                                })
                            ;
                        } else {
                            snackbarModule
                                .display({
                                    message: 'Adresse email ou mot de passe invalide',
                                    position: 'top',
                                    color: 'error',
                                })
                            ;
                        }
                    })
                ;
            }
        }

        public mounted() {
            if (this.$route.query.email) {
                this.username = this.$route.query.email as string;
            } else if (this.initialUsername) {
                this.username = this.initialUsername;
            }
        }
    }
